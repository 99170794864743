



















































































































































































import i18n from '@/lang';
import { getSchoolDetailApi } from '@/api/school_api';
import { CusRoute } from '@/router';
import { downloadImage } from '@/utils';
import { Carousel } from 'element-ui';
import { Vue, Component, Ref } from 'vue-property-decorator';
import indexStore from '@/store/index'
import { UserStoreModule } from '@/store/UserModule';
import {getPubUrlApi} from '@/api/user_api';
const WX = require('weixin-js-sdk');
import Clipboard from 'clipboard'
@Component
export default class UserSchoolDetail extends Vue{
  @Ref('carouselRef') carouselRef!: Carousel;
  loading = false;
  school = {
    fkAreaCountryId: 0,
    country: '',
    nameChn: '',
    name: '',
    nature: '',         // 公立||私立
    website: '',        // 官网
    establishedDate: '',
    dataType: 0
  }
  animateShowIdList: any[] = [
    { id: 'baseInfoTitle', show: false, toTop: 0, offset: '20%', dom: null },
    { id: 'baseInfoList', show: false, toTop: 0, offset: '20%', dom: null },
    { id: 'descTitle', show: false, toTop: 0, offset: '20%', dom: null },
    { id: 'swiperWrap', show: false, toTop: 0, offset: '20%', dom: null },
    { id: 'descList', show: false, toTop: 0, offset: '20%', dom: null },
    { id: 'addressTitle', show: false, toTop: 0, offset: '20%', dom: null },
    { id: 'addressList', show: false, toTop: 0, offset: '20%', dom: null },
    { id: 'youshiTitle', show: false, toTop: 0, offset: '10%', dom: null },
    { id: 'advantageList', show: false, toTop: 0, offset: '10%', dom: null },
    { id: 'luquTitle', show: false, toTop: 0, offset: '10%', dom: null },
    { id: 'luquList', show: false, toTop: 0, offset: 0, dom: null },
    { id: 'infoFifthTit', show: false, toTop: 0, offset: 0, dom: null },
    { id: 'infoFifthList', show: false, toTop: 0, offset: 0, dom: null },
    { id: 'infoSixthTit', show: false, toTop: 0, offset: 0, dom: null },
    { id: 'infoSixthList', show: false, toTop: 0, offset: 0, dom: null },
    { id: 'fileList', show: false, toTop: 0, offset: 0, dom: null },
  ]
  publicFilesUrl:any='';
  iconList = ['&#xe708;','&#xe7c0;','&#xe654;','&#xe615;','&#xe6ca;','&#xe6fb;'];
  characters: Array<{key: string, value: string}> = [];
  fileList: any[] = [];     // pdf文件下载
  swiperList = [];   // 轮播图下载
  schoolInfomation: any = {};
  videoSrc = '';
  curSwiperIndex = 1;
  scrollTop = 0;
  winH = 0;
  addressToBottom = 0;
  oldScrollTop = 0;
  background = '';     // 详情背景图
  cityBackground = ''; // 地理位置背景图
  headActive = true;
  schoolInfoTitle: any = {} // title
  get homeBgmTop(){
    return this.winH < this.scrollTop ? this.winH : this.scrollTop;
  }
  get addressBgmShow(){
    return (this.winH * 2 < this.scrollTop && this.scrollTop < this.addressToBottom);
  }
  get getUrl() {
    return (url: any) => {// indexStore.getters.getPublicFileUrl
      return url? this.publicFilesUrl + url: ''
    }
  }
  async mounted(){
    this.getPubUrl();
  }
  public async getSchoolDetailFun(){
    try {
      window.scrollTo(0,0)
      this.winH = window.innerHeight;
      // let schoolInfoTypeList = await getSchoolInfoSelectApi();
      const schoolInfoTitle: any = {
        1: '',  
        2: '',      
        3: '',     
        4: '',
        5: '',
        6: '',
      };
      let schoolInfoRet = await getSchoolDetailApi(this.$route.params.id);
      if (schoolInfoRet.data.code == 1003) {
        const {
          institutionDto, 
          institutionInfoDto, 
          pdfFile, 
          picFile, 
          movFiles, 
          background, 
          cityBackground
        } = schoolInfoRet.data.data;
        
        const schoolInfomation: any = {
          IB_INFO_FIRST: [],
          IB_INFO_SECOND: [],
          IB_INFO_THIRD: [],
          IB_INFO_FOURTH: [],
          IB_INFO_FIFTH: [],
          IB_INFO_SIXTH: [],
        }
        if (institutionInfoDto && institutionInfoDto.length >= 0) {
          for (let i = 0, lng = institutionInfoDto.length; lng > i; i++){
            const typeId = institutionInfoDto[i].typeKey;
            if (schoolInfomation[typeId]) {

              if(institutionInfoDto[i].fkInfoTypeId){
                schoolInfoTitle[institutionInfoDto[i].fkInfoTypeId] = institutionInfoDto[i].title;
              }
              if(typeId == 'IB_INFO_THIRD'){
                schoolInfomation[typeId].push(...this.filterAdvantageItem(institutionInfoDto[i].description))
              } else {
                schoolInfomation[typeId].push(institutionInfoDto[i]);
              }
            }
          }
        }
        this.schoolInfoTitle = schoolInfoTitle;
        this.schoolInfomation = schoolInfomation;
        this.school = institutionDto;
        this.characters = institutionDto.characters ? JSON.parse(institutionDto.characters) : ''; 
        this.fileList = pdfFile || [];
        this.swiperList = picFile || [];
        this.loading = true;
        this.videoSrc = movFiles ? this.getUrl(movFiles[0].fileKey) : '';
        this.background = background ? this.getUrl(background[0].fileKey) : '';
        this.cityBackground = cityBackground ? this.getUrl(cityBackground[0].fileKey) : '';
        this.setDomAnimate();
        window.addEventListener('scroll', this.onPageScroll, {passive: false});
        window.addEventListener('resize',this.onWindowResize);
      }
    } catch (e){
      console.log(e)
    }
  }
  public async getPubUrl(){
    const {data}:any=await getPubUrlApi();
    this.publicFilesUrl=data.data.publicFilesUrl;
    this.getSchoolDetailFun();
  }
  getOffsetTop(dom: any){
    let offsetTop = dom.offsetTop;
    function getParent(dom: any){
      if(dom.offsetParent){
        offsetTop += dom.offsetParent.offsetTop;
        getParent(dom.offsetParent);
        return;
      }
    }
    getParent(dom);
    return offsetTop
  }
  beforeRouteLeave(to: CusRoute, from: CusRoute, next: Function){
    for (let i = 0, lng = this.animateShowIdList.length; lng > i; i++){
      const item = this.animateShowIdList[i];
      item.dom && (item.dom = null);
    }
    window.removeEventListener('resize',this.onWindowResize);
    window.removeEventListener('scroll', this.onPageScroll)
    next();
  }
  get titleTrainsitionDelay(){
    return (index:number)=>{
      return `transition-delay: ${index*0.2}s`
    }
  }
  private prevDate = 0;
  private throttleTime = 0;
  private ticking = false;
  private lastScroll = 0;
  onPageScroll(){
    const delay = 100;
    const nowDate = Date.now();
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.scrollTop = window.scrollY;
        if (this.lastScroll == 0){
          this.headActive = true;
        } else if (this.lastScroll > this.scrollTop){
          // up
          this.headActive = true;
        } else {
          // down
          this.headActive = false;
        }
        this.lastScroll = this.scrollTop;
        this.ticking = false;
      });

      this.ticking = true;
    }

    
    if ( nowDate - this.prevDate > delay && !this.throttleTime){
      this.judgeShowAnimation();
      this.prevDate = Date.now();
    } else {
      if ( this.throttleTime ){
        clearTimeout(this.throttleTime);
        this.throttleTime = 0;
      } else {
        this.throttleTime = setTimeout(()=>{
          this.judgeShowAnimation();
        }, delay)
      }
    }
  }
  private sizeTicking= false;
  onWindowResize(){
    if(!this.sizeTicking){
      window.requestAnimationFrame(()=>{
        this.winH = window.innerHeight;
        this.setDomAnimate();
        this.sizeTicking = false;
      })
      this.sizeTicking = true;
    }
    
  }
  judgeShowAnimation(){
    const scrollTop = window.scrollY;
    const winHeight = window.innerHeight;
    this.oldScrollTop = scrollTop;
    for (let i = 0, lng = this.animateShowIdList.length; lng > i ; i++){
      const item = this.animateShowIdList[i]
      if (!item.dom) {
        const domItem = document.getElementById(item.id);
        if(domItem) {
          item.toTop = this.getOffsetTop(domItem);
          item.dom = domItem;
        }
      }
      if(!item.dom) return;
      const classes = item.dom.classList;
      let offset = item.offset.toString().indexOf('%') >= 0? parseInt(item.offset) * winHeight / 100 : item.offset 
      if(item.toTop + offset < scrollTop + winHeight){
        if (!item.show) {
          classes.add('active')
          item.show = true;
        } 
      } else {
        classes.remove('active')
        item.show = false;
      }
    }
  }
  scrollMainInfor(){
    window.scrollTo({
      top: this.winH,
      left: 0,
      behavior: 'smooth',
    })
  }
  onPrevSwiper() {
    this.carouselRef.prev()
  }
  onNextSwiper() {
    this.carouselRef.next()
  }
  
  onChangeSwiper(index: number){
    this.curSwiperIndex = index+1;
  }
  downloadPdf(index: number){
    const fileitem = this.fileList[index];
    WX.miniProgram.navigateTo({
      url: `/pages/index/loadfile?src=${fileitem.fileKey}&name=${fileitem.fileNameOrc}`
    });
    downloadImage(fileitem.fileKey, fileitem.fileNameOrc);
  }
  goSchoolList(){
    if(this.school.dataType == 1){
      return;
    }

    if (window.history.length > 1) {
      this.$router.back()
    } else {
      this.$router.push('/')
    }
  }
  copyLink(){
    let _this=this;
    let clipboard=new Clipboard('#copy');
    clipboard.on('success',function(){
      _this.$message.success(i18n.t('copySuccess') as string);
      clipboard.destroy();
    })
    clipboard.on('error',function(){
      _this.$message.error(i18n.t('copyError') as string);
      clipboard.destroy();
    })
  }
  filterAdvantageItem(paragraph: string){
    if(!paragraph) return '';
    let newParagraph1 = paragraph.replace(/(<br\/>)/g, '');
    const isPureParagraph = /^(<p>.*<\/p>|\s|[a-zA-Z0-9])*$/
    if(isPureParagraph.test(newParagraph1)){
      const reg = /<p[\s\w='"]*>.*?<\/p>/g;     // 输入富文本一定会有p标签，所以获取p标签
      const newParagraph2 = newParagraph1.match(reg)
      if(newParagraph2 && this.school.dataType == 0){
        return newParagraph2.map(item=>`<div class="dot"></div><div class="p">${item}</div>`);
      }
    }
    return [this.school.dataType == 0?`<div class="dot"></div><div class="p">${paragraph}</div>`: `<div class="p">${paragraph}</div>`];
  }
  setDomAnimate(){
    this.$nextTick(()=>{
      // 遍历所有动画距离顶部的距离
      for (let i = 0, lng = this.animateShowIdList.length; lng > i ; i++){
        const item = this.animateShowIdList[i]
        const dom = document.getElementById(item.id);
        if(dom){
          item.toTop = this.getOffsetTop(dom);
          item.dom = dom;
        }
      }
      let addressWrapDom = document.querySelector('.youshiwrap');
      if (addressWrapDom){
        this.addressToBottom = this.getOffsetTop(addressWrapDom) + 20;
      }
    })
  }
}
